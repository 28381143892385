/* globals document  */

import { GaContext, InfluxContext } from 'shared/components/Tracking/types';
import { decode as decodeHtmlSafeJson } from 'shared/utils/dom/htmlSafeJson';

type InheritedContext = {
  namespace?: string;
  overrideNamespace?: boolean;
  gaContext?: GaContext;
  influxContextAttributes?: InfluxContext['attributes'];
};

export function delegateEvent(
  eventType: string,
  descendentSelector: string,
  callback: (element: Element, event: Event) => void
) {
  document.addEventListener(
    eventType,
    (event) => {
      if (!(event.target instanceof Element)) {
        return;
      }

      // Returns null if no matching parentNode is found
      const elem = event.target.closest(descendentSelector);

      if (elem && elem instanceof Element) {
        callback(elem, event);
      }
    },
    true
  );
}

export function decodeTrackingAttribute<T>(attributeValue: string | null): T | null {
  if (!attributeValue) return null;

  return decodeHtmlSafeJson(attributeValue);
}

export default function readContext(element: Element): InheritedContext {
  const mergedContext: InheritedContext = {};

  let currentElement: Element | null = element;

  while (currentElement) {
    // @TODO: do we need to deal with data-ctx-ga-namespace and data-ctx-influx-namespace?
    const namespace = currentElement.getAttribute('data-ctx-namespace');
    const overrideNamespace = currentElement.getAttribute('data-ctx-override-namespace');

    if (namespace && !mergedContext.overrideNamespace) {
      // Prepend because we are traversing the DOM from the target element to the root
      mergedContext.namespace = mergedContext.namespace ? `${namespace}.${mergedContext.namespace}` : namespace;

      if (overrideNamespace) {
        // This will effectively ignore all the parent namespaces
        mergedContext.overrideNamespace = true;
      }
    }

    const gaContextBase64JsonString = currentElement.getAttribute('data-ctx-ga');

    if (gaContextBase64JsonString) {
      const gaContext = decodeHtmlSafeJson(gaContextBase64JsonString);

      if (gaContext) {
        mergedContext.gaContext = { ...gaContext, ...mergedContext.gaContext };
      }
    }

    const influxAttributesBase64JsonString = currentElement.getAttribute('data-ctx-influx');

    if (influxAttributesBase64JsonString) {
      const influxAttributes = decodeHtmlSafeJson(influxAttributesBase64JsonString);

      if (influxAttributes) {
        mergedContext.influxContextAttributes = {
          ...influxAttributes,
          ...mergedContext.influxContextAttributes,
        };
      }
    }

    currentElement = currentElement.parentElement;
  }

  return mergedContext;
}
