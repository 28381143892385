// From lodash@4.17.20

const hasOwnProperty = Object.prototype.hasOwnProperty;
const objectProto = Object.prototype;
const toString = Object.prototype.toString;

function isPrototype(value: any) {
  const Ctor = value && value.constructor;
  const proto = (typeof Ctor === 'function' && Ctor.prototype) || objectProto;

  return value === proto;
}

function getTag(value: any) {
  if (value == null) {
    return value === undefined ? '[object Undefined]' : '[object Null]';
  }
  return toString.call(value);
}

export default function isEmpty(value: any): boolean {
  if (value == null) {
    return true;
  }
  if (Array.isArray(value) || typeof value === 'string') {
    return value.length === 0;
  }

  const tag = getTag(value);

  if (tag === '[object Map]' || tag === '[object Set]') {
    return value.size === 0;
  }

  if (isPrototype(value)) {
    return !Object.keys(value).length;
  }

  for (const key in value) {
    if (hasOwnProperty.call(value, key)) {
      return false;
    }
  }

  return true;
}
