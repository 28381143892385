/* globals localStorage window */

const STICKY_REFERRER_LOCAL_STORAGE_KEY = 'prStickyReferrer';
const STICKY_REFERRER_VERSION = 1;
const STICKY_REFERRER_MAX_AGE = 24 * 60 * 60 * 1000; // 24 hours in ms

type StickyReferrerStoredObject = {
  stickyReferrer?: string;
  version: number;
  expiresAt: number; // timestamp
};

function getStickyReferrerStoredObject(): StickyReferrerStoredObject | undefined {
  // Browsers with enhanced privacy protection might block access to localStorage,
  // so we wrap the call with try-catch.
  try {
    const item = localStorage.getItem(STICKY_REFERRER_LOCAL_STORAGE_KEY);

    if (item) {
      return JSON.parse(item);
    }
  } catch {
    // ...
  }

  return undefined;
}

export function getStickyReferrer(): string | undefined {
  const storedObject = getStickyReferrerStoredObject();

  if (storedObject) {
    return storedObject.stickyReferrer;
  }

  return undefined;
}

export function getEntryPoint(): string | undefined {
  return window.prCachedEntryPoint;
}

export function setEntryPoint(entryPoint: string) {
  window.prCachedEntryPoint = entryPoint;
}

export function setStickyReferrer(stickyReferrer?: string): void {
  const objectToStore: StickyReferrerStoredObject = {
    stickyReferrer,
    version: STICKY_REFERRER_VERSION,
    expiresAt: Date.now() + STICKY_REFERRER_MAX_AGE,
  };

  // Browsers with enhanced privacy protection might block access to localStorage,
  // so we wrap the call with try-catch.
  try {
    localStorage.setItem(STICKY_REFERRER_LOCAL_STORAGE_KEY, JSON.stringify(objectToStore));
  } catch {
    // ...
  }
}

export function unsetStickyReferrerIfExpired(): boolean {
  const storedObject = getStickyReferrerStoredObject();

  if (storedObject && storedObject.expiresAt < Date.now()) {
    // Browsers with enhanced privacy protection might block access to localStorage,
    // so we wrap the call with try-catch.
    try {
      localStorage.removeItem(STICKY_REFERRER_LOCAL_STORAGE_KEY);
    } catch {
      // ...
    }

    return true;
  }

  return false;
}
