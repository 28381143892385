/* globals window */

import { getStickyReferrer, getEntryPoint } from 'shared/utils/referrerStorage';

type CommandMetadata = {
  stickyReferrer?: string;
  entryPoint?: string;
  screenResolution: string;
  devicePixelRatio: number;
};

export default function getCommandMetadata(): CommandMetadata | undefined {
  if (__SERVER__) {
    return undefined;
  }

  const screenWidth = window.screen.width || window.innerWidth;
  const screenHeight = window.screen.height || window.innerHeight;

  return {
    stickyReferrer: getStickyReferrer(),
    entryPoint: getEntryPoint(),
    screenResolution: `${screenWidth}x${screenHeight}`,
    devicePixelRatio: window.devicePixelRatio,
  };
}
