import { Required } from 'utility-types';

/* globals document location window */

import getTrackedEventMetadata from 'shared/utils/getTrackedEventMetadata';
import { decode as decodeHtmlSafeJson } from 'shared/utils/dom/htmlSafeJson';

import GoogleAnalyticsClient from './GoogleAnalyticsClient';
import InfluxAnalyticsClient, { DataPoint, InfluxEmitOptions } from './InfluxAnalyticsClient';
import { GaDataAttribute, InfluxDataAttribute } from './types';

const gaClient = new GoogleAnalyticsClient();
const influxClient = new InfluxAnalyticsClient();

function readHttpStatusCode() {
  if (window.peekHttpStatusCode) {
    return window.peekHttpStatusCode() || 200;
  }

  // Fallback to reading it from the meta tag (rendered by react-helmet)
  const httpStatusCodeMetaTag = document.querySelector<HTMLMetaElement>('meta[name="http-status-code"]');

  if (!httpStatusCodeMetaTag || !httpStatusCodeMetaTag.content) {
    return 200;
  }

  const asNumber = Number(httpStatusCodeMetaTag.content);

  if (Number.isNaN(asNumber)) {
    return 200;
  }

  return asNumber;
}

function readGlobalGaContext() {
  const context: Record<string, any> = {
    httpStatusCode: readHttpStatusCode(),
  };

  const elements = document.querySelectorAll('[data-ctx-global-ga]');

  for (const element of elements) {
    const globalGaContextBase64JsonString = element.getAttribute('data-ctx-global-ga');

    if (globalGaContextBase64JsonString) {
      const globalGaContext = decodeHtmlSafeJson(globalGaContextBase64JsonString);

      if (globalGaContext) {
        Object.assign(context, globalGaContext);
      }
    }
  }

  return context;
}

export function triggerGaEvent(params: Required<GaDataAttribute, 'action'>) {
  if (!params.action) {
    console.error('triggerGaEvent: action is required', params);
    return;
  }

  gaClient.updateGlobalProperties(readGlobalGaContext());

  gaClient.gtag('event', params.action, {
    eventCategory: params.category,
    eventLabel: params.label,
    eventValue: params.value,
    ...params.fieldsObject,
  });
}

export function triggerGaPageview() {
  gaClient.updateGlobalProperties(readGlobalGaContext());

  const url = new URL(location.href);

  // Replace all uuids with <uuid>
  // E.g.: /reviews/00000000-0000-0000-0000-000000000000 -> /reviews/<uuid>
  url.pathname = url.pathname.replace(
    /[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}/,
    '<UUID>'
  );

  gaClient.gtag('event', 'page_view', {
    page_location: url.toString(),
  });
}

export function triggerInfluxEvent(params: InfluxDataAttribute, options?: InfluxEmitOptions) {
  const dataPoint: DataPoint = {
    ...params,
    attributes: {
      value: 1,
      ...(getTrackedEventMetadata() as DataPoint['attributes']),
      ...params.attributes,
    },
  };

  if (!dataPoint.attributes?.routeName) {
    // This was probably emitted from a ghost DOM node that couldn't read
    // its parents attributes, ignore it. Might happen with impressions + waitFor.
    return;
  }

  influxClient.emit(dataPoint, options);
}
