/* globals document window */
const NOTIFY_ENDPOINT = `https://${process.env.SERVICE__ANALYTICS__HOST}/notify`;

export type DataPoint = {
  measurement: string;
  attributes?: Record<string, string | number>;
};

export type InfluxEmitOptions = {
  forceFlush?: boolean;
};

export default class InfluxAnalyticsClient {
  private bufferedDataPoints: DataPoint[];

  private flushTimeoutInMs: number;

  private flushTimeoutId: number | undefined;

  constructor(flushTimeoutInMs: number = process.env.IN_TEST === 'TRUE' ? 0 : 1000) {
    this.flushTimeoutInMs = flushTimeoutInMs;
    this.bufferedDataPoints = [];

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        this.flush();
      }
    });
  }

  private log(dataPoint: DataPoint) {
    if (process.env.IN_TEST === 'TRUE') {
      console.info(
        '[INFLUX]',
        // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
        require('shared/utils/jsonStableStringify').default(dataPoint)
      );
    }
  }

  private flush() {
    if (this.bufferedDataPoints.length === 0) {
      return;
    }

    const formData = new FormData();

    for (let i = 0; i < this.bufferedDataPoints.length; i++) {
      const point = this.bufferedDataPoints[i];

      formData.append(`dataPoints[${i}][measurement]`, point.measurement);

      if (point.attributes) {
        for (const attributeName in point.attributes) {
          const value = point.attributes[attributeName];

          if (typeof value !== 'undefined') {
            formData.append(`dataPoints[${i}][attributes][${attributeName}]`, String(value));
          }
        }
      }
    }

    this.bufferedDataPoints = [];

    const data = new URLSearchParams(Array.from(formData) as any);

    window
      .fetch(NOTIFY_ENDPOINT, {
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        // We send the cookies to make debugging easier on Bugsnag in case something explodes on the server.
        credentials: 'include',
      })
      .then((response) => {
        if (response.status === 400 && window.bugsnagClient) {
          // We report from the client as well so that we can track the user actions (breadcrumbs).
          // We can match the client error with the server error by using cookie values or IP address, for ex.
          window.bugsnagClient.notify(new Error('InfluxAnalyticsClient: Bad request'));
        }
      })
      .catch(function noop() {});
  }

  public emit(dataPoint: DataPoint, options?: InfluxEmitOptions) {
    this.log(dataPoint);
    this.bufferedDataPoints.push(dataPoint);

    // Don't let the request payload get too big
    if (options?.forceFlush || this.bufferedDataPoints.length >= 20) {
      this.flush();
    }

    // Debouncing logic
    if (this.flushTimeoutId) {
      window.clearTimeout(this.flushTimeoutId);
    }

    if (this.flushTimeoutInMs) {
      this.flushTimeoutId = window.setTimeout(() => {
        this.flush();
      }, this.flushTimeoutInMs);
    } else {
      this.flush();
    }
  }
}
