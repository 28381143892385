/* globals window document */

import toReactCallbackName from 'shared/helpers/string/toReactCallbackName';
import compact from 'shared/utils/array/compact';

import readContext, { delegateEvent, decodeTrackingAttribute } from './domUtils';
import { triggerGaEvent, triggerInfluxEvent } from './triggerEvent';
import { GaDataAttribute, InfluxDataAttribute } from './types';

const TRACKED_EVENT_TYPES = ['click', 'focusin', 'focusout', 'change', 'mousedown'];

const elementToTrackCountMap = new Map<Element, number>();

function cleanUp() {
  // Remove elements that are no longer in the DOM
  for (const element of elementToTrackCountMap.keys()) {
    if (!document.body.contains(element)) {
      elementToTrackCountMap.delete(element);
    }
  }
}

export default function setupSimpleEventTracking() {
  window.TrackingUtils = {
    triggerGaEvent,
    triggerInfluxEvent,
  };

  for (const eventType of TRACKED_EVENT_TYPES) {
    delegateEvent(eventType, `[data-track-ga-${eventType}], [data-track-influx-${eventType}]`, (element) => {
      const trackCount = elementToTrackCountMap.get(element) || 0;

      if (element.hasAttribute(`data-track-once-${eventType}`) && trackCount > 0) {
        return;
      }

      const gaTrackingAttributeId = element.getAttribute(`data-track-ga-${eventType}`);
      const influxTrackingAttributeId = element.getAttribute(`data-track-influx-${eventType}`);

      if (gaTrackingAttributeId) {
        const gaTrackingParams = decodeTrackingAttribute<GaDataAttribute>(gaTrackingAttributeId);
        const context = readContext(element);

        if (gaTrackingParams) {
          triggerGaEvent({
            action: toReactCallbackName(eventType),
            ...context.gaContext,
            ...gaTrackingParams,
            // Merge with inherited namespace
            category: compact([context.namespace, gaTrackingParams.category]).join('.'),
          });
        }
      }

      if (influxTrackingAttributeId) {
        const influxTrackingParams = decodeTrackingAttribute<InfluxDataAttribute>(influxTrackingAttributeId);
        const context = readContext(element);

        if (influxTrackingParams) {
          triggerInfluxEvent({
            measurement: influxTrackingParams.measurement,
            // Merge with inherited attributes
            attributes: {
              spot: context.namespace,
              ...context.influxContextAttributes,
              ...influxTrackingParams.attributes,
            },
          });
        }
      }

      elementToTrackCountMap.set(element, trackCount + 1);

      cleanUp();
    });
  }
}
