/* globals document window HTMLElement */
import debounce from 'shared/utils/function/debounce';
import { requestIdleCallback } from 'shared/utils/scheduling';
import { Beeper } from 'shared/utils/debug/Beeper';
import { DebugModeToggler } from 'shared/utils/debug/DebugModeToggler';

import { triggerInfluxEvent } from './triggerEvent';
import readContext, { decodeTrackingAttribute } from './domUtils';
import { InfluxDataAttribute } from './types';

function injectDebugStyle(window: Window) {
  const prefix = 'data-track-impression-debug-';

  const style = window.document.createElement('style');
  style.type = 'text/css';
  style.innerHTML = `
[${prefix}observe-count], [${prefix}before-count], [${prefix}after-count], [${prefix}direct-count] {
  position: relative;
}

[${prefix}observe-count]::after, [${prefix}before-count]::after, [${prefix}after-count]::after, [${prefix}direct-count]::after {
  content: attr(${prefix}observe-count) ':' attr(${prefix}direct-count) '|' attr(${prefix}before-count) '/' attr(${prefix}after-count);
  position: absolute;
  top: 0;
  right: 0;
  font-family: monospace;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 5px;
  font-size: 10px;
  border-radius: 3px;
  z-index:10000;
}

/* Grey by default */
[data-track-impression] {
  box-shadow: inset 0px 0px 10px 1px rgba(0, 0, 255, 0.5) !important;
}

/* Green when count is one */
[${prefix}direct-count="1"],
[${prefix}before-count="1"][${prefix}after-count="1"] {
  box-shadow: inset 0px 0px 10px 1px rgba(0, 255, 0, 0.5) !important;
}

/* Red when any count is greater than (other than) 1 */
[${prefix}direct-count]:not([${prefix}direct-count="1"]),
[${prefix}before-count]:not([${prefix}before-count="1"]),
[${prefix}after-count]:not([${prefix}after-count="1"]) {
  box-shadow: inset 0px 0px 10px 1px rgba(255,0,0,0.5) !important;
}`;

  window.document.body.appendChild(style);
}

/**
 * @TODO:
 * - Track nodes if possible (simpler), unlesss it's the only solution and using dom doesn't work
 * - Make sure all listing impressions are counted (if from different areas on the page)
 * - Make it less sensitive
 * - Test Page Navigation and rerender
 * - Test if long reviews are tracked on mobile. Specially if visible straight away
 */
class ImpressionTrackingManager {
  public static DATASET_ATTRIBUTE_NAME = 'data-track-impression';

  private observer: IntersectionObserver;

  private timeoutIds: number[] = [];

  private alreadyTrackedImpressionIds: { [impressionId: string]: true } = {};

  // @TODO
  private alreadyTrackedDomNodes = new WeakSet<HTMLElement>();

  private onImpression: (element: HTMLElement) => void;

  private constructor(
    IntersectionObserver: typeof window.IntersectionObserver,
    private getAllRelatedNodes: () => NodeListOf<HTMLElement>,
    private debugMode: boolean = true
  ) {
    this.onImpression = (element: HTMLElement) => {
      this.observer.unobserve(element);

      const tracking = decodeTrackingAttribute<InfluxDataAttribute>(element.getAttribute('data-track-impression'));

      if (!tracking || !tracking.impressionId) return;

      if (tracking.impressionId && this.alreadyTrackedImpressionIds[tracking.impressionId]) return;

      const baseCallback = () => {
        const context = readContext(element);

        triggerInfluxEvent({
          measurement: tracking.measurement,
          attributes: { spot: context.namespace, ...context.influxContextAttributes, ...tracking.attributes },
        });
      };

      this.alreadyTrackedImpressionIds[tracking.impressionId] = true;

      // Improve deterministic behaviour for e2e tests
      if (__DEVELOPMENT__ && process.env.IN_TEST === 'TRUE') return baseCallback();

      if (tracking.waitFor) {
        if (__DEVELOPMENT__) this.debug(element, 'before');

        this.timeoutIds.push(
          window.setTimeout(() => {
            baseCallback();

            if (__DEVELOPMENT__) this.debug(element, 'after');
          }, tracking.waitFor)
        );

        return;
      }

      if (__DEVELOPMENT__) this.debug(element, 'direct');

      baseCallback();
    };

    this.observer = new window.IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          const element = entry.target as HTMLElement;

          if (__DEVELOPMENT__ && this.debugMode)
            console.debug('Impression Check for', entry.target, entry, entry.isIntersecting);

          if (!entry.isIntersecting) continue;

          // Might happen during page transitions, skip. Weird: isn't reference to dom element weak?
          if (!document.contains(element)) {
            if (__DEVELOPMENT__) console.error('Remove comment above if this ever happens.');

            continue;
          }

          this.onImpression(element);
        }
      },
      { threshold: [0] }
    );
  }

  public reset() {
    this.observer.disconnect();

    if (__DEVELOPMENT__) for (const element of this.getAllRelatedNodes()) this.debug(element, 'unobserve');

    this.timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
    this.timeoutIds = [];

    this.alreadyTrackedImpressionIds = {};
  }

  private isElementContainedInRoot(element: HTMLElement) {
    const rect = element.getBoundingClientRect();

    return rect.top >= 0 && rect.bottom <= window.innerHeight && rect.left >= 0 && rect.right <= window.innerWidth;
  }

  public checkStateOfRelevantNodes() {
    for (const element of this.getAllRelatedNodes()) {
      requestIdleCallback(() => {
        if (!this.isElementContainedInRoot(element)) {
          this.observer.observe(element);
          if (__DEVELOPMENT__) this.debug(element, 'observe');
          return;
        }

        this.onImpression(element);
      });
    }
  }

  private debug(element: HTMLElement, eventName: string) {
    if (__DEVELOPMENT__) {
      if (!this.debugMode) return;

      console.debug(eventName, element);

      const attributeName = `data-track-impression-debug-${eventName}-count`;
      const initialValue = parseInt(element.getAttribute(attributeName) || '0', 10);
      element.setAttribute(attributeName, String(initialValue + 1));

      Beeper.singleton().beep(
        {
          direct: { frequency: 300, duration: 100 },
          before: { frequency: 400, duration: 200 },
          after: { frequency: 450, duration: 400 },
          observe: { frequency: 500, duration: 200 },
          unobserve: { frequency: 550, duration: 400 },
        }[eventName]
      );
    }
  }

  public static setupFromGlobal(window): ImpressionTrackingManager | undefined {
    if (!window.IntersectionObserver) {
      console.debug(`window.IntersectionObserver is not defined`);
      return;
    }

    const manager = new ImpressionTrackingManager(
      window.IntersectionObserver,
      () => window.document.querySelectorAll(`[${this.DATASET_ATTRIBUTE_NAME}]`) as NodeListOf<HTMLElement>,
      (__DEVELOPMENT__ && DebugModeToggler.fromGlobal(window, 'debug_impression_tracking').isEnabled()) || false
    );

    setTimeout(() => {
      window.document.addEventListener(
        'renderUpdate',
        // Don't reset state or it will duplicate impressions
        debounce(() => manager.checkStateOfRelevantNodes(), 100)
      );

      document.addEventListener('navigated', () => {
        requestIdleCallback(() => {
          manager.reset();
          manager.checkStateOfRelevantNodes();
        });
      });

      manager.checkStateOfRelevantNodes();
    }, 500); // Limit overcrowded computation

    if (__DEVELOPMENT__ && process.env.IN_TEST !== 'TRUE' && manager.debugMode) {
      injectDebugStyle(window);
    }

    if (__DEVELOPMENT__) console.debug('Impression Tracking Manager Ready \n', manager);

    return manager;
  }
}

export default function setupImpressionTracking() {
  ImpressionTrackingManager.setupFromGlobal(window);
}
