export default function compact<T>(array: Array<T | null | undefined | false | '' | 0> | null | undefined): T[] {
  const result: T[] = [];
  let resIndex = 0;

  if (!array) {
    return result;
  }

  for (const value of array) {
    if (value) {
      result[resIndex++] = value;
    }
  }

  return result;
}
