/* globals gtag */
import isEmpty from 'shared/utils/isEmpty';
import isObject from 'shared/utils/lang/isObject';

type GAProperties = Record<string, number | string | boolean | undefined>;

// Keep it in sync with the Google Analytics property
const USER_PROPERTY_KEYS = ['actorRole', 'brand', 'isLoggedIn', 'managedBrandId'];

// GA's API doesn't allow us to set these globally, so they need to be sent
// together with each event.
let globalEventProperties: GAProperties = {};
// Used to clear the object later on, because gtag doesn't have an API for
// that, so we have to set every key to undefined.
const allGlobalPropertyKeys = new Set<string>();

const ourGtag: Gtag.Gtag = (...args) => {
  if (__CLIENT__) {
    // @TODO
    // @NOTE: don't use `process.env.IN_TEST` because we want to optimise it at
    // build time due to the `require` call.
    if (process.env.IN_TEST === 'TRUE') {
      console.info(
        '[GA]',
        ...args.map((item) =>
          isObject(item)
            ? // eslint-disable-next-line
              require('shared/utils/jsonStableStringify').default(item)
            : item
        )
      );
    }

    if (typeof gtag === 'function') {
      // The API is gtag('event', 'event_name', event_properties)
      if (args[0] === 'event') {
        if (args.length < 3) {
          args.push(globalEventProperties);
        } else {
          const lastArg = args[args.length - 1];

          if (typeof lastArg === 'object') {
            args[args.length - 1] = {
              ...globalEventProperties,
              ...lastArg,
            };
          } else {
            console.error(`Unexpected usage of gtag`, { metadata: { args } });

            args[args.length - 1] = globalEventProperties;
          }
        }
      }

      // @ts-ignore
      gtag(...args);
    }
  }

  return;
};

export default class GoogleAnalyticsClient {
  public gtag: Gtag.Gtag = ourGtag;

  public mergeGlobalProperties(newGlobalProperties: GAProperties) {
    const userProperties: typeof globalEventProperties = {};

    for (const key in newGlobalProperties) {
      if (USER_PROPERTY_KEYS.includes(key)) {
        userProperties[key] = newGlobalProperties[key];
      } else {
        globalEventProperties[key] = newGlobalProperties[key];
      }
    }

    if (!isEmpty(userProperties)) {
      ourGtag('set', 'user_properties', userProperties);
    }

    for (const key of Object.keys(newGlobalProperties)) {
      allGlobalPropertyKeys.add(key);
    }
  }

  public updateGlobalProperties(newGlobalProperties: GAProperties) {
    // Clear existing ones first
    globalEventProperties = {};

    const clearedGlobalProperties: GAProperties = {};

    for (const key of allGlobalPropertyKeys) {
      clearedGlobalProperties[key] = undefined;
    }

    this.mergeGlobalProperties({
      ...clearedGlobalProperties,
      ...newGlobalProperties,
    });
  }
}
