/* globals document window */
import { Location } from 'history';

import { triggerInfluxEvent } from './triggerEvent';
import readContext, { decodeTrackingAttribute } from './domUtils';
import { InfluxDataAttribute } from './types';

const elementToThenMap = new Map<HTMLElement, Date | null>();
let impressionTrackingIntersectionObserver: IntersectionObserver | undefined;

const elementToPausedAtMap = new Map<HTMLElement, number>();
const elementToDurationSoFarMap = new Map<HTMLElement, number>();
const MOCKED_DURATION_FOR_TEST = 1234;

function getDurationAttribute(element: HTMLElement): number {
  return elementToDurationSoFarMap.get(element) || 0;
}

function getPausedAtAttribute(element: HTMLElement): number | undefined {
  return elementToPausedAtMap.get(element);
}

function accumulateDuration(element: HTMLElement) {
  const then = elementToThenMap.get(element);

  if (then) {
    const durationSoFarInMs = getDurationAttribute(element);
    const pausedAt = getPausedAtAttribute(element);
    const diff = pausedAt ? Date.now() - pausedAt : Date.now() - then.getTime();

    elementToDurationSoFarMap.set(element, durationSoFarInMs + diff);
  }
}

function resumeTracking(cleanup: boolean = false) {
  const elements = document.querySelectorAll('[data-track-impression-duration]') as NodeListOf<HTMLElement>;

  if (cleanup) {
    for (const element of elements) {
      elementToPausedAtMap.delete(element);
      elementToThenMap.delete(element);

      impressionTrackingIntersectionObserver?.unobserve(element);
    }
  }

  if (elements.length === 0) {
    return;
  }

  for (const element of elements) {
    elementToPausedAtMap.delete(element);
    elementToThenMap.set(element, null);
    impressionTrackingIntersectionObserver?.observe(element);
  }
}

function pauseTracking() {
  for (const element of elementToThenMap.keys()) {
    accumulateDuration(element);
    elementToThenMap.delete(element);
    elementToPausedAtMap.set(element, new Date().getTime());

    if (impressionTrackingIntersectionObserver) {
      impressionTrackingIntersectionObserver.unobserve(element);
    }
  }
}

function triggerEvents() {
  for (const element of elementToThenMap.keys()) {
    if (!document.contains(element)) {
      continue;
    }

    const trackingAttributeId = element.getAttribute('data-track-impression-duration');

    if (trackingAttributeId) {
      const params = decodeTrackingAttribute<InfluxDataAttribute>(trackingAttributeId);

      if (!params) {
        return;
      }

      const context = readContext(element);

      const durationSoFarInMs = getDurationAttribute(element);
      const then = elementToThenMap.get(element);
      const sinceThen = then ? Date.now() - then.getTime() : 0;
      const totalDurationInMs =
        process.env.IN_TEST === 'TRUE' ? MOCKED_DURATION_FOR_TEST : durationSoFarInMs + sinceThen;

      triggerInfluxEvent(
        {
          measurement: params.measurement,
          // Merge with inherited attributes
          attributes: {
            ...context.influxContextAttributes,
            ...params.attributes,
            value: totalDurationInMs,
          },
        },
        {
          // Flush right way to prevent losing data points while leaving the page
          forceFlush: true,
        }
      );

      elementToThenMap.delete(element);
    }
  }
}

function cleanUp() {
  // Remove elements that are no longer in the DOM
  for (const element of elementToDurationSoFarMap.keys()) {
    if (!document.body.contains(element)) {
      elementToDurationSoFarMap.delete(element);
    }
  }

  for (const element of elementToPausedAtMap.keys()) {
    if (!document.body.contains(element)) {
      elementToPausedAtMap.delete(element);
    }
  }
}

export default function setupImpressionDurationTracking() {
  if (window.IntersectionObserver) {
    impressionTrackingIntersectionObserver = new window.IntersectionObserver((entries) => {
      for (const entry of entries) {
        const isVisible = entry.isIntersecting;
        const element = entry.target as HTMLElement;

        if (isVisible) {
          elementToThenMap.set(element, new Date());
        } else {
          accumulateDuration(element);
          elementToThenMap.set(element, null);
        }
      }
    });
  }

  document.addEventListener('beforeNavigate', (event: CustomEvent<{ from: Location; to: Location }>) => {
    if (event.detail.from.pathname === event.detail.to.pathname) {
      return;
    }

    triggerEvents();
    resumeTracking(true);
    cleanUp();
  });

  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      pauseTracking();
    } else {
      resumeTracking();
    }

    cleanUp();
  });

  window.addEventListener('beforeunload', triggerEvents, false);

  resumeTracking();
}
