/* globals document */

import { triggerGaPageview } from './triggerEvent';

export default function setupPageviewTracking() {
  document.addEventListener('navigated', () => {
    triggerGaPageview();
  });

  triggerGaPageview();
}
