/* globals window */

const root = typeof window !== 'undefined' ? window : global;

export const requestIdleCallback: typeof window.requestIdleCallback =
  root.requestIdleCallback ||
  ((cb) => {
    const start = Date.now();

    return setTimeout(() => {
      cb({
        didTimeout: false,
        timeRemaining: () => {
          return Math.max(0, 50 - (Date.now() - start));
        },
      });
    }, 1);
  });

export const cancelIdleCallback: typeof window.cancelIdleCallback =
  root.cancelIdleCallback ||
  ((id) => {
    clearTimeout(id);
  });
